<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-3 pb-2"
      >
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container class="pb-0 mb-1 pt-1 pl-6 pr-3">
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0 pl-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0 pb-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters"
              >
                <v-row class="mb-n2">
                  <!-- fecha desde -->
                  <v-col cols="3" sm="3" md="3" class="py-0 pr-0 pl-1">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hide-details="auto"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde =
                              helpers.parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(
                                helpers.parseDateToIso(fechaDesdeSelected)
                              ) <=
                                new Date(
                                  helpers.parseDateToIso(fechaHastaSelected)
                                ) || 'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="
                          fechaDesdeSelected = helpers.formatDate(fechaDesde)
                        "
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Fecha hasta -->
                  <v-col cols="3" sm="3" md="3" class="py-0 pl-2 pr-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaHasta =
                              helpers.parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(
                                helpers.parseDateToIso(fechaDesdeSelected)
                              ) <=
                                new Date(
                                  helpers.parseDateToIso(fechaHastaSelected)
                                ) || 'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="
                          fechaHastaSelected = helpers.formatDate(fechaHasta)
                        "
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="py-0 pr-0 pl-2">
                    <v-autocomplete
                      v-model="tipoIngresoEgresoSelected"
                      :items="tipoIngresoEgresoItems"
                      item-text="value"
                      item-value="id"
                      return-object
                      label="Tipo"
                      hide-details="auto"
                      outlined
                      @change="setTipoComprobante(tipoIngresoEgresoSelected.id)"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0 pl-2">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tipoComprobantesItems"
                      item-text="tCompNombre"
                      item-value="tCompId"
                      return-object
                      label="Tipo de comprobante"
                      outlined
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Letra y numero de comprobante -->
                  <v-col cols="12" md="1" class="pl-1 py-0 pr-1">
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="letraComprobante"
                      outlined
                      dense
                      persistent-hint
                      v-mask="'A'"
                      label="Letra"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col class="p-0" cols="12" md="1">
                    <v-text-field
                      type="text"
                      v-model="sucursalComprobante"
                      outlined
                      dense
                      class="center-input"
                      v-mask="'#####'"
                      :rules="[
                        rules.minMaxNumber(sucursalComprobante, 1, 99999),
                      ]"
                      persistent-hint
                      label="Suc."
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col class="p-0 pl-1 pr-2" cols="12" md="2">
                    <v-text-field
                      type="text"
                      v-model="nroComprobante"
                      outlined
                      dense
                      v-mask="'########'"
                      persistent-hint
                      label="Número"
                      :rules="[rules.minMaxNumber(nroComprobante, 1, 99999999)]"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0 pr-0 pl-0">
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="concepto"
                      outlined
                      label="Concepto"
                      dense
                      persistent-hint
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pr-0 pl-2">
                    <v-autocomplete
                      v-if="parametroGeneralMultimoneda"
                      v-model="monedaSelected"
                      :items="monedas"
                      return-object
                      item-value="id"
                      item-text="value"
                      outlined
                      :rules="rules.required"
                      clearable
                      dense
                      label="Moneda"
                      id="monedaPVIV"
                      hide-details="auto"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2" align="end" class="text-right pt-2 pl-0 pb-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="resetForm"
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-row>
            <v-col
              col="12"
              md="12"
              class="pt-0 pb-0"
              align="start"
              align-self="center"
            >
              <v-data-table
                :headers="headersIngresosEgresos"
                :items="ingresosEgresoItems"
                :search="search"
                height="100%"
                class="elevation-1"
                :loading="isLoadingTable"
                show-expand
                :expanded.sync="expanded"
                item-key="mprovId"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="6" class="py-0">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          dense
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        align-self="end"
                        class="text-right py-0 pr-3"
                      >
                        <v-btn
                          v-if="canCreate"
                          color="primary"
                          @click="goToEditPVIV"
                          >Nuevo</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <span>{{
                    helpers.floatToMoneyString(item.total, "", true)
                  }}</span>
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="handleExpansion(item, isExpanded)"
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        >{{
                          isExpanded ? "mdi-account-check" : "mdi-account"
                        }}</v-icon
                      >
                    </template>
                    <span>Usuarios</span>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <strong>Fecha alta:</strong>
                    {{ item.fecAlta ? item.fecAlta : "Sin datos" }}
                    <strong style="padding-left: 50px">Usuario alta:</strong>
                    {{ item.usuAlta ? item.usuAlta : "Sin datos" }}
                    <strong style="padding-left: 50px"
                      >Fecha modificación:</strong
                    >
                    {{ item.fecModi ? item.fecModi : "Sin datos" }}
                    <strong style="padding-left: 50px"
                      >Usuario modificación:</strong
                    >
                    {{ item.usuModi ? item.usuModi : "Sin datos" }}
                  </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="canEdit"
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="goToEditPVIV(item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip left v-if="canDelete">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deletePVIV(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                  <v-tooltip
                    left
                    v-if="
                      canAnular && !item.obs.toLowerCase().includes('anulado')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="openAnularModal(item)"
                      >
                        {{ anularIcon }}
                      </v-icon>
                    </template>
                    <span>Anular</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="12" align="right" class="py-3">
            <v-menu transition="scale-transition" bottom origin="center center">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn
                  outlined
                  v-bind="attrs"
                  :loading="loadingBtnExport"
                  color="primary"
                  v-on="menu"
                  >Exportar</v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link @click="exportDetalle">
                  <v-list-item-title> Detalle general</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="exportDetalleImputaContable">
                  <v-list-item-title>
                    Detalle con imputación contable</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
      <DeleteDialog
        :titleProp="titleAnular"
        :maxWidth="'35%'"
        :confirmButtonText="'Eliminar'"
        :openDelete.sync="openModalDelete"
        :isLoading="loadingBtnDelete"
        @onDeleteItem="confirmDelete()"
      />
      <!-- MODAL ANULAR -->
      <v-dialog
        v-model="openModalAnularPVIV"
        v-if="openModalAnularPVIV"
        @keydown.esc="openModalAnularPVIV = false"
        max-width="80%"
        persistent
      >
        <AnularPVIV
          @closeModal="(openModalAnularPVIV = false), applyFilters()"
          :mProvId="idToAnular"
        ></AnularPVIV>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
import helpers from "@/utils/helpers.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
import DeleteDialog from "@/components/shared/DeleteDialog";
import AnularPVIV from "@/components/modules/fondos/AnularPVIV.vue";

export default {
  name: "ConsultaPVIV",
  components: { Ayuda, FiltersSelected, PageHeader, DeleteDialog, AnularPVIV },
  directives: { mask },
  data: (vm) => ({
    rules: rules,
    helpers: helpers,
    title: "Ingresos / egresos varios",
    titleAnular: "¿Desea eliminar el comprobante?",
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    anularIcon: enums.icons.CIRCLE_NONE,
    search: "",
    loadingBtnDelete: false,
    idToDelete: null,
    allowedActions: null,
    optionCode: enums.webSiteOptions.CONSULTA_PV_IV,
    showHelp: false,
    openModalDelete: false,
    itemToDelete: null,
    loadingBtnExport: false,
    isFormValid: false,
    showFilters: true,
    showExpand: false,
    //filters
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 20)
      .toISOString()
      .substr(0, 10),
    fechaDesdeSelected: helpers.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 20)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: null,
    fechaHastaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu2: null,
    tipoComprobanteSelected: null,
    tipoComprobantesItems: [],
    letraComprobante: "",
    sucursalComprobante: "",
    nroComprobante: "",
    concepto: "",
    tipoIngresoEgresoSelected: { id: "T", value: "Todos" },
    tipoIngresoEgresoItems: [
      {
        id: "I",
        value: "Ingresos",
      },
      {
        id: "E",
        value: "Egresos",
      },
      {
        id: "T",
        value: "Todos",
      },
    ],
    openModalDelete: false,
    isLoadingTable: false,
    expanded: [],
    filtersApplied: [],
    ingresosEgresoItems: [],
    headersIngresosEgresos: [
      {
        text: "Fecha",
        sortable: true,
        value: "fechaToShow",
      },
      {
        text: "Comprobante",
        sortable: true,
        value: "nroComp",
      },
      {
        text: "Total",
        sortable: true,
        align: "right",
        value: "total",
      },
      {
        text: "Concepto",
        sortable: true,
        value: "obs",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end",
      },
    ],
    canSeeMultimoneda: false,
    canDelete: false,
    canEdit: false,
    canCreate: false,
    canAnular: false,
    openModalAnularPVIV: false,
    idToAnular: null,
    monedas: [],
    monedaPorDefecto: null,
    monedaSelected: null,
    parametroGeneralMultimoneda: false,
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como página visitada si ingreso por url
      this.optionCode
    );
    await this.setPermisos();
    await this.setCombos();
    if (this.$store.state.filtersConsultaPVIV) await this.setFilters();
  },
  methods: {
    ...mapActions({
      getPagosIngresosVarios: "fondos/getPagosIngresosVarios",
      setAlert: "user/setAlert",
      getTipoCompPvIv: "fondos/getTipoCompPvIv",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      deletePagosVarios: "fondos/deletePagosVarios",
      getComprobantesConImputacionContable:
        "fondos/getComprobantesConImputacionContable",
    }),
    async setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_PV_IV:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_PV_IV:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_PV_IV:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ANULAR_PV_IV:
            this.canAnular = true;
            break;
          default:
            break;
        }
      });
    },
    goToEditPVIV(item) {
      this.$router.push({
        name: "EditPVIV",
        params: {
          mprovId: item?.mprovId ?? null,
          esAnulacion:  item?.mprovId ? item.obs.toLowerCase().includes("anulado") : false,
        },
      });
    },
    async setCombos() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      this.setTipoComprobante("T");
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      this.monedaPorDefecto = this.monedas.find((x) => x.bool == true);
      this.monedaPorDefectoNom = this.monedaPorDefecto.value;
      this.monedaSelected = this.monedaPorDefecto;
    },
    async setTipoComprobante(id) {
      this.tipoComprobantesItems = await this.getTipoCompPvIv(0);
      if (id == "I")
        this.tipoComprobantesItems = this.tipoComprobantesItems.filter(
          (x) => x.esIngresosVarios
        );
      if (id == "E")
        this.tipoComprobantesItems = this.tipoComprobantesItems.filter(
          (x) => x.esPagosVarios
        );
    },
    async setFilters() {
      const filters = this.$store.state.filtersConsultaPVIV;
      this.fechaDesde = filters.fechaDesde;
      this.fechaDesdeSelected = helpers.formatDate(filters.fechaDesde);
      this.fechaHasta = filters.fechaHasta;
      this.fechaHastaSelected = helpers.formatDate(filters.fechaHasta);
      this.letraComprobante = filters.letra;
      this.sucursalComprobante = filters.sucursal == 0 ? "" : filters.sucursal;
      this.nroComprobante = filters.nroComp == 0 ? "" : filters.nroComp;
      this.tipoIngresoEgresoSelected = this.tipoIngresoEgresoItems.find(
        (x) => x.id == filters.tipo
      );
      await this.setTipoComprobante("T");
      this.tipoComprobanteSelected = this.tipoComprobantesItems.find(
        (x) => x.tCompId == filters.tCompId
      );
      this.concepto = filters.obs;
      this.monedaSelected = this.monedas.find((x) => x.id == filters.monedaId);
      this.applyFilters();
    },
    async applyFilters() {
      this.isLoadingTable = true;
      if (!this.tipoIngresoEgresoSelected)
        this.tipoIngresoEgresoSelected = { id: "T", value: "Todos" };
      this.customizeFiltersApplied();
      const data = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        letra:
          this.letraComprobante == null || this.letraComprobante == undefined
            ? ""
            : this.letraComprobante.trim(),
        sucursal:
          this.sucursalComprobante == null ||
          this.sucursalComprobante == undefined ||
          this.sucursalComprobante == ""
            ? 0
            : this.sucursalComprobante.trim(),
        nroComp:
          this.nroComprobante == null || this.nroComprobante == ""
            ? 0
            : this.nroComprobante.trim(),
        tipo: this.tipoIngresoEgresoSelected.id,
        tCompId: this.tipoComprobanteSelected
          ? this.tipoComprobanteSelected.tCompId
          : 0,
        obs: this.concepto,
        monedaId: this.monedaSelected.id,
      };

      this.$store.state.filtersConsultaPVIV = data;
      try {
        const response = await this.getPagosIngresosVarios(data);
        if (response) this.ingresosEgresoItems = response;
      } catch {}
      this.isLoadingTable = false;
    },
    customizeFiltersApplied() {
      this.showFilters = false;
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesdeSelected",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.tipoIngresoEgresoSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "tipoIngresoEgresoSelected",
          label: "Tipo",
          model: this.tipoIngresoEgresoSelected.value,
        });
      }
      if (this.parametroGeneralMultimoneda && this.monedaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "monedaSelected",
          label: "Moneda",
          model: this.monedaSelected.value,
        });
      }
      if (
        this.letraComprobante ||
        this.sucursalComprobante ||
        this.nroComprobante
      ) {
        this.filtersApplied.splice(4, 1, {
          key: "nroComprobante",
          label: "Número comprobante",
          model: `${this.letraComprobante ?? ""} - ${
            this.sucursalComprobante ?? ""
          } - ${this.nroComprobante ?? ""}`,
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "tipoComprobanteSelected.tCompNombre",
          label: "Tipo comprobante",
          model: this.tipoComprobanteSelected.tCompNombre,
        });
      }
      if (this.concepto) {
        this.filtersApplied.splice(5, 1, {
          key: "concepto",
          label: "Concepto",
          model: this.concepto,
        });
      }
    },
    async exportDetalleImputaContable() {
      this.loadingBtnExport = true;
      const param = {
        mProvIds: this.ingresosEgresoItems.map((x) => x.mprovId),
      };
      await this.getComprobantesConImputacionContable(param);
      this.loadingBtnExport = false;
    },
    openAnularModal(item) {
      this.openModalAnularPVIV = true;
      this.idToAnular = item.mprovId;
    },
    exportDetalle() {
      this.loadingBtnExport = true;
      let result = [];
      this.ingresosEgresoItems?.forEach((x) =>
        result.push({
          ["Fecha"]: x.fechaToShow,
          ["Comprobante"]: x.nroComp,
          ["Total"]: x.total,
          ["Empresa"]: x.entFacNombre,
          ["Observaciones"]: x.obs,
          ["Moneda"]: x.moneda,
          ["Fecha alta"]: x.fecAlta,
          ["Usuario alta"]: x.usuAlta,
          ["Fecha modificación"]: x.fecModi,
          ["Usuario modificación"]: x.usuModi,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(
        formato,
        "Listado comprobantes ingresos / egresos"
      );
      this.loadingBtnExport = false;
    },
    deletePVIV(item) {
      this.openModalDelete = true;
      this.idToDelete = item.mprovId;
    },
    async confirmDelete() {
      try {
        this.loadingBtnDelete = true;
        const response = await this.deletePagosVarios(this.idToDelete);
        if (response.status == 200) {
          if (response.data.data.length > 0)
            this.setAlert({
              type:
                response.data.data == "Comprobante Anulado"
                  ? "success"
                  : "warning",
              message:
                response.data.data == "Comprobante Anulado"
                  ? "Pagos varios eliminado con éxito"
                  : response.data.data.toString(),
            });
          this.applyFilters();
        } else {
          this.setAlert({
            type: "warning",
            message: response.data.data.toString(),
          });
        }
      } catch {}
      this.loadingBtnDelete = false;
      this.openModalDelete = false;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>
<template>
  <v-card :loading="loadView">
    <v-container v-if="!loadView">
      <v-form v-model="isFormValid" ref="anularPVIV-form" id="anularPVIV-form">
        <v-row>
          <v-col cols="6" class="pb-2 pt-0 pl-4">
            <PageHeader :title="title" class="pt-0 pb-0" />
          </v-col>
          <v-col cols="6" align="end" class="pt-1 pb-0 pr-1">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="to-right" icon @click="closeModal">
                  <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="p-2 pl-0">
          <v-card-text class="pt-0 pb-3">
            <v-row class="">
              <v-col cols="12" class="p-0" align="end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text to-right"
                      @click="showHeader = !showHeader"
                    >
                      {{ !showHeader ? chevronDownIcon : chevronUpIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showHeader ? "Mostrar cabecera" : "Ocultar cabecera"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-if="showHeader"
                v-model="isFormValid"
                ref="formAnularPVIV"
                form="formAnularPVIV"
                id="formAnularPVIV"
                @submit.prevent="anularPVIV()"
              >
                <!-- Nombre -->
                <v-row>
                  <v-col cols="3" class="py-0 pr-2">
                    <v-autocomplete
                      v-model="empresaSelected"
                      :items="empresas"
                      item-text="value"
                      item-value="id"
                      return-object
                      label="Empresas"
                      outlined
                      dense
                      disabled
                      filled
                      :rules="rules.required"
                      id="empresaPVIV"
                      @change="setImputacionContableEmpresa()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0 pr-2 pl-0">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tipoComprobantesItems"
                      item-text="tCompNombre"
                      item-value="tCompId"
                      return-object
                      label="Tipo de comprobante"
                      outlined
                      filled
                      disabled
                      hide-details="auto"
                      dense
                      :rules="rules.required"
                      id="tipoCompPVIV"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="pr-0 pl-0 py-0">
                    <v-menu
                      ref="fecha"
                      v-model="menuFecha"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaSelected"
                          label="Fecha"
                          hint="Formato DD/MM/AAAA"
                          @blur="fecha = helpers.parseDateToIso(fechaSelected)"
                          outlined
                          hide-details
                          dense
                          filled
                          disabled
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          id="fechaPVIV"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fecha"
                        no-title
                        @change="fechaSelected = helpers.formatDate(fecha)"
                        @input="menuFecha = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="pr-0 pl-2 py-0">
                    <v-menu
                      ref="fechaContable"
                      v-model="menuFechaContable"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaContableSelected"
                          label="Fecha contable"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaContable = helpers.parseDateToIso(
                              fechaContableSelected
                            )
                          "
                          outlined
                          filled
                          disabled
                          hide-details
                          dense
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          id="fechaContablePVIV"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaContable"
                        no-title
                        @change="
                          fechaContableSelected =
                            helpers.formatDate(fechaContable)
                        "
                        @input="menuFechaContable = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="mt-n4 pl-3">
                  <!-- Letra y numero de comprobante -->
                  <v-col cols="12" md="1" class="pl-1 py-0 pr-1">
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="letraComprobante"
                      outlined
                      dense
                      persistent-hint
                      v-mask="'A'"
                      label="Letra"
                      id="letraPVIV"
                      hide-details="auto"
                      :rules="rules.required"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col class="p-0" cols="12" md="1">
                    <v-text-field
                      type="text"
                      v-model="sucursalComprobante"
                      outlined
                      dense
                      class="center-input"
                      v-mask="'#####'"
                      persistent-hint
                      label="Sucursal"
                      id="sucursalPVIV"
                      hide-details="auto"
                      :rules="rules.required"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col class="p-0 pl-1 pr-2" cols="12" md="2">
                    <v-text-field
                      type="text"
                      v-model="nroComprobante"
                      outlined
                      dense
                      v-mask="'########'"
                      persistent-hint
                      label="N°"
                      hide-details="auto"
                      id="numeroPVIV"
                      :rules="rules.required"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="parametroGeneralMultimoneda"
                    cols="12"
                    md="2"
                    class="pt-0 pr-0 pl-0"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedas"
                      return-object
                      item-value="id"
                      item-text="value"
                      outlined
                      :rules="rules.required"
                      clearable
                      dense
                      filled
                      disabled
                      label="Moneda"
                      id="monedaPVIV"
                      hide-details="auto"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="monedaDistintaDefecto && parametroGeneralMultimoneda"
                    cols="12"
                    md="2"
                    class="pt-0 pr-0 pl-2 pb-0"
                  >
                    <currency-input
                      dense
                      v-model="cotizacion"
                      outlined
                      filled
                      disabled
                      :rules="
                        monedaDistintaDefecto && parametroGeneralMultimoneda
                          ? rules.required
                          : []
                      "
                      :options="currencyOptions"
                      hide-details="auto"
                      label="Cotización"
                      id="cotizacionPVIV"
                    ></currency-input>
                  </v-col>
                  <v-col
                    v-if="monedaDistintaDefecto && parametroGeneralMultimoneda"
                    cols="12"
                    md="2"
                    class="py-0 pl-2 pr-0"
                  >
                    <currency-input
                      type="number"
                      class="center-input"
                      v-model="importeMonedaExtranjera"
                      outlined
                      filled
                      disabled
                      :label="`Importe (${monedaExtranjeraNom})`"
                      dense
                      @blur="calcularTotalEnPeso()"
                      :rules="[]"
                      :options="currencyOptions"
                      id="importeMonedaExtranjeraPVIV"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col cols="12" md="2" class="py-0 pl-2 pr-0">
                    <currency-input
                      type="number"
                      class="center-input"
                      v-model="importe"
                      outlined
                      filled
                      disabled
                      :label="`Importe (${monedaPorDefectoNom})`"
                      dense
                      id="importeMonedaDefectoPVIV"
                      @blur="calcularTotalEnDolar()"
                      :options="currencyOptions"
                      :rules="[]"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                </v-row>
                <v-row
                  :class="
                    tipoComprobanteSelected && tCompEsPagoRetencion
                      ? 'mb-n4'
                      : 'mb-n9'
                  "
                >
                  <v-col
                    cols="12"
                    md="12"
                    :class="
                      parametroGeneralMultimoneda
                        ? 'py-0 pl-4 pr-0 mt-n1'
                        : 'py-0 pl-4 pr-0 mt-2'
                    "
                  >
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="concepto"
                      outlined
                      filled
                      disabled
                      label="Concepto"
                      id="conceptoPVIV"
                      dense
                      persistent-hint
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-row>
            <v-col cols="6" class="pb-0 pl-8">
              <span class="primary--text font-weight-medium"
                >Retenciones y Valores</span
              >
            </v-col>
          </v-row>
          <v-card-text>
            <v-data-table
              :headers="filteredHeadersValores"
              :items="valoresItems"
              v-model="valoresSelected"
              class="elevation-1 mt-n2 py-1"
              dense
              show-select
              @click:row="
                valoresSelected.some(
                  (item) =>
                    item.mpIdAnulaVarios !== 0 && item.mpIdAnulaVarios !== null
                )
                  ? {}
                  : selectWhenClickRow($event)
              "
              @input="calcularImporteAnulacion"
              item-key="mpValId"
              :hide-default-footer="valoresItems && valoresItems.length <= 10"
            >
              <template v-slot:[`header.data-table-select`]>
                <v-simple-checkbox
                  v-model="selectAll"
                  @click="selectAllItems()"
                  :ripple="false"
                  hide-details
                />
              </template>
              <template
                v-slot:[`item.data-table-select`]="{ isSelected, select, item }"
              >
                <template>
                  <v-simple-checkbox
                    :value="isSelected"
                    color="primary"
                    :disabled="
                      item.mpIdAnulaVarios !== 0 &&
                      item.mpIdAnulaVarios !== null
                    "
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
              </template>
              <template v-slot:[`item.imp`]="{ item }">
                <span>
                  {{ helpers.floatToMoneyString(item.imp, "", true) }}
                </span>
              </template>
              <template v-slot:[`item.importeComp`]="{ item }">
                <span>{{
                  helpers.floatToMoneyString(item.importeComp, "", true)
                }}</span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-col
              :cols="
                parametroGeneralMultimoneda && !monedaDistintaDefecto ? 6 : 4
              "
              align="end"
            >
              <!-- <h6>Importe a revertir:</h6> -->
            </v-col>
            <v-col cols="2" align="end" class="text-right pr-2 p-0">
              <currency-input
                :label="`Importe a revertir (PESO)`"
                v-model="importeRevertir"
                readonly
                filled
                disabled
                dense
                outlined
                :rules="[]"
                :options="currencyOptions"
                hideDetails="true"
              ></currency-input>
            </v-col>
            <v-col
              v-if="parametroGeneralMultimoneda && monedaDistintaDefecto"
              cols="2"
              align="end"
              class="text-right"
            >
              <h6>Totales valores:</h6>
            </v-col>
            <v-col
              v-if="parametroGeneralMultimoneda && monedaDistintaDefecto"
              cols="2"
              align="end"
              class="text-right pr-2 p-0"
            >
              <currency-input
                :label="`Total (${monedaExtranjeraNom})`"
                v-model="totalValoresMonedaExtranjera"
                readonly
                filled
                disabled
                dense
                outlined
                :rules="[]"
                :options="currencyOptions"
                hideDetails="true"
              ></currency-input>
            </v-col>
            <v-col
              v-if="!monedaDistintaDefecto || !parametroGeneralMultimoneda"
              cols="2"
              align="end"
              class="text-right"
            >
              <h6>Total valores:</h6>
            </v-col>
            <v-col cols="2" align="end" class="text-right pr-2 p-0">
              <currency-input
                :label="`Total (${monedaPorDefectoNom})`"
                v-model="totalValores"
                readonly
                disabled
                filled
                dense
                outlined
                :rules="[]"
                :options="currencyOptions"
                :hideDetails="'true'"
              ></currency-input>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-row class="px-4">
        <v-col class="pt-4 pl-0 pr-2 pb-1" cols="3">
          <v-autocomplete
            v-model="tipoComprobanteAnulacionSelected"
            return-object
            :items="tiposComprobantesAnulacion"
            outlined
            filled
            disabled
            item-value="id"
            :rules="rules.required"
            item-text="value"
            ref="comboTipoCompAnula"
            id="comboTipoCompAnula"
            hide-details="auto"
            dense
            label="Tipo comprobante anulación"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2" class="pr-0 pl-0 pb-0 pt-4">
          <v-menu
            ref="fecha"
            v-model="menuFechaAnulacion"
            transition="scale-transition"
            :close-on-content-click="false"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaAnulacionSelected"
                label="Fecha"
                @blur="
                  fechaAnulacion = helpers.parseDateToIso(
                    fechaAnulacionSelected
                  )
                "
                outlined
                hide-details="auto"
                dense
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                :rules="
                  rules.required.concat(
                    rules.validDate,
                    new Date(
                      helpers.parseDateToIso(fechaContableAnulacionSelected)
                    ) >=
                      new Date(
                        helpers.parseDateToIso(fechaAnulacionSelected)
                      ) || 'Formato incorrecto'
                  )
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaAnulacion"
              no-title
              @change="
                fechaAnulacionSelected = helpers.formatDate(fechaAnulacion)
              "
              @input="menuFechaAnulacion = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" class="pr-1 pl-2 pt-4 pb-0">
          <v-menu
            ref="fechaContable"
            :close-on-content-click="false"
            v-model="menuFechaContableAnulacion"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaContableAnulacionSelected"
                label="Fecha contable"
                @blur="
                  fechaContableAnulacion = helpers.parseDateToIso(
                    fechaContableAnulacionSelected
                  )
                "
                outlined
                hide-details="auto"
                dense
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                :rules="
                  rules.required.concat(
                    rules.validDate,
                    new Date(
                      helpers.parseDateToIso(fechaContableAnulacionSelected)
                    ) >=
                      new Date(
                        helpers.parseDateToIso(fechaAnulacionSelected)
                      ) || 'Formato incorrecto'
                  )
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaContableAnulacion"
              no-title
              @change="
                fechaContableAnulacionSelected = helpers.formatDate(
                  fechaContableAnulacion
                )
              "
              @input="menuFechaContableAnulacion = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" class="px-4">
          <v-row>
            <v-col class="p-0 pl-1 pt-1" md="5">
              <v-text-field
                v-model="letraComprobanteAnula"
                :rules="rules.required"
                v-mask="'A'"
                dense
                outlined
                hide-details="auto"
                label="Letra"
                filled
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="p-0 pl-1 pt-1" md="7">
              <v-text-field
                v-model="sucursalComprobanteAnula"
                v-mask="'#####'"
                dense
                outlined
                hide-details="auto"
                label="Sucursal"
                disabled
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pl-1 pr-0 pt-4 p-0" cols="3">
          <v-text-field
            v-model="nroComprobanteAnula"
            v-mask="'#########'"
            dense
            outlined
            hide-details="auto"
            label="Número"
            disabled
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" class="pt-0 pr-4">
          <v-text-field
            v-model="obsAnulacion"
            dense
            outlined
            hide-details="auto"
            label="Observaciones de la anulación"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right pr-4 pt-1" align="end">
          <v-btn
            form="reversionOp-form"
            :loading="loadingSaveBtn"
            :disabled="
              !isFormValid ||
              valoresSelected.length == 0 ||
              importeRevertir == 0.0 ||
              !tipoComprobanteAnulacionSelected ||
              cantValoresOriginal == valoresSelected.length
            "
            color="primary"
            @click="saveAnulacionPagosVarios()"
            >Grabar anulación</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "AnularPVIV.vue",
  directives: { mask },
  components: { CurrencyInput, ConfirmDialog, PageHeader },
  props: {
    mProvId: {
      type: Number,
      require: true,
      default: 1,
    },
  },
  data() {
    return {
      enums: enums,
      rules: rules,
      helpers: helpers,
      closeIcon: enums.icons.CLOSE,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      showHeader: true,
      concepto: "",
      loadingSaveBtn: false,
      tCompEsPagoRetencion: false,
      title: "Anular pagos/ingresos varios",
      empresas: [],
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 999999999999999,
        },
        precision: 2,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true,
      },
      fechaSelected: null,
      importeRevertir: 0.0,
      fecha: null,
      menuFecha: null,
      fechaContableSelected: null,
      fechaContable: null,
      menuFechaContable: null,
      obsAnulacion: "",
      menuFechaAnulacion: null,
      menuFechaContableAnulacion: null,
      fechaContableAnulacionSelected: helpers.formatDate(
        new Date(new Date()).toISOString().substr(0, 10)
      ),
      fechaContableAnulacion: new Date().toISOString().substr(0, 10),
      fechaAnulacion: new Date().toISOString().substr(0, 10),
      fechaAnulacionSelected: helpers.formatDate(
        new Date(new Date()).toISOString().substr(0, 10)
      ),
      empresaSelected: null,
      monedaSelected: null,
      monedaPorDefecto: null,
      siglaMonedaPorDefecto: null,
      monedaPorDefectoNom: null,
      isFormValid: false,
      letraComprobante: "",
      sucursalComprobante: "",
      nroComprobante: "",

      letraComprobanteAnula: "",
      sucursalComprobanteAnula: "",
      nroComprobanteAnula: 0,
      parametroGeneralMultimoneda: false,
      selectAll: false,
      tipoComprobanteSelected: null,
      tipoComprobanteAnulacionSelected: null,
      tiposComprobantesAnulacion: [],
      tiposComprobantes: [],
      valoresSelected: [],
      cantValoresOriginal: 0,
      loadView: true,
      monedas: [],
      cotizacion: 1,
      totalValores: 0.0,
      totalValoresMonedaExtranjera: 0.0,
      importe: 0.0,
      headersValores: [
        { text: "Tipo valor", value: "tvNombre", sortable: false },
        { text: "Banco", sortable: false, value: "bancoNombre" },
        { text: "Cuenta", sortable: false, value: "bctaNombre" },
        { text: "Fecha", sortable: false, value: "fechaToShow" },
        { text: "A orden de", sortable: false, value: "aOrdenDe" },
        { text: "Número", sortable: false, value: "numero" },
        { text: "Importe", align: "end", sortable: false, value: "imp" },
        {
          text: "Importe",
          align: "end",
          sortable: false,
          value: "importeComp",
        },
      ],
      valoresItems: [],
      tipoComprobantesItems: [],
      tieneNumeradorAutomatico: false,
    };
  },
  computed: {
    monedaDistintaDefecto() {
      if (this.monedaSelected)
        return this.monedaSelected.id !== this.monedaPorDefecto.id;
    },
    monedaExtranjeraNom() {
      if (this.monedaSelected && this.parametroGeneralMultimoneda) {
        let result = this.monedaDistintaDefecto
          ? `${this.monedaSelected.value}`
          : "";

        return result;
      }
    },
    filteredHeadersValores() {
      return this.headersValores.filter((header) => {
        //usamos importeComp solo cuando la moneda del proveedor es distinta de la porDefecto.
        if (this.monedaDistintaDefecto && header.value == "importeComp")
          header.text = `Importe ${this.monedaExtranjeraNom?.toLowerCase()}`;

        if (header.value == "imp")
          header.text = this.monedaDistintaDefecto
            ? `Importe ${this.monedaPorDefectoNom?.toLowerCase()}`
            : "Importe";

        if (!this.monedaDistintaDefecto && header.value === "importeComp")
          return false;

        return true;
      });
    },
  },
  watch: {
    loadView(val) {
      if (!val) {
        this.$store.dispatch("user/changeAppLoading", {
          status: false,
          text: "",
          opaque: false,
        });
      }
    },
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: true,
      text: "",
      opaque: false,
    });
    await this.setCombos();
    await this.setDatosEditPVIV();
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getRetencionesAPagar: "fondos/getRetencionesAPagar",
      getAllTipoComprobantes: "proveedores/getAllTipoComprobantes",
      getTiposValoresModuloProveedores:
        "proveedores/getTiposValoresModuloProveedores",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      grabarPagosVarios: "fondos/grabarPagosVarios",
      getTipoCompPvIvAbm: "fondos/getTipoCompPvIvAbm",
      grabarAnularPagosVarios: "fondos/grabarAnularPagosVarios",
      setAlert: "user/setAlert",
      getDatosEditPVIV: "fondos/getDatosEditPVIV",
    }),
    async setCombos() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      const tiposValores = await this.getTiposValoresModuloProveedores();
      this.tipoRetencionesItems = tiposValores;
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresas = entidadesFacturantes;
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      this.monedaPorDefecto = this.monedas.find((x) => x.bool == true);
      this.monedaPorDefectoNom = this.monedaPorDefecto.value;
    },
    async setDatosEditPVIV() {
      const response = await this.getDatosEditPVIV(this.mProvId);
      if (response) {
        this.concepto = response.obs;
        this.empresaSelected = this.empresas.find(
          (x) => x.id == response.entFacId
        );
        // fecha
        this.fechaSelected = response.fechaString;
        let partesFechaToFomat = this.fechaSelected.split("/");
        this.fecha = `${partesFechaToFomat[2]}-${partesFechaToFomat[1]}-${partesFechaToFomat[0]}`;
        // fecha contable
        this.fechaContableSelected = response.fechaContString;
        let partesFechaContableToFomat = this.fechaContableSelected.split("/");
        this.fechaContable = `${partesFechaContableToFomat[2]}-${partesFechaContableToFomat[1]}-${partesFechaContableToFomat[0]}`;
        this.tipoComprobantesItems = await this.getTipoCompPvIvAbm(
          this.empresaSelected.id
        );
        this.tipoComprobanteSelected = this.tipoComprobantesItems.find(
          (x) => x.tCompId == response.tCompId
        );

        const tipoComprobante = await this.getAllTipoComprobantes(
          response.entFacId
        );
        this.tiposComprobantesAnulacion = tipoComprobante;
        this.tipoComprobanteAnulacionSelected =
          this.tiposComprobantesAnulacion.find(
            (x) => (x.tCompId = this.tipoComprobanteSelected?.tcompIdRevertir)
          );
        await this.setDatosTipoComprobante();
        this.letraComprobante = response.letra;
        this.sucursalComprobante = response.suc;
        this.nroComprobante = response.numero;
        this.monedaSelected = response.monedaIdComp
          ? this.monedas.find((x) => x.id == response.monedaIdComp)
          : this.monedaPorDefecto;
        this.importe = response.total;
        this.cotizacion = !response.monedaIdComp ? null : response.cotizacion;
        this.importeMonedaExtranjera =
          response.monedaIdComp != null ? response.totalComp : null;
        if (response.listaValores?.length > 0) {
          if (response.listaValores.some((x) => x.mpIdAnulaVarios !== 0)) {
            this.valoresSelected = response.listaValores.filter(
              (x) => x.mpIdAnulaVarios !== 0
            );
            this.cantValoresOriginal = this.valoresSelected.length;
            this.setAlert({
              type: "warning",
              message: `Existen valores anulados`,
            });
          }
          this.valoresItems = response.listaValores;
          this.valoresItems = this.valoresItems.map((item) => {
            if (item.fecha !== null) {
              let date = new Date(item.fecha);
              item.fechaToShow = date.toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
            }
            return item;
          });
          this.totalValores = response.total;
          this.totalValoresMonedaExtranjera = response.totalComp ?? null;
          this.calcularImporteAnulacion();
        }
      }
      this.loadView = false;
    },
    calcularImporteAnulacion() {
      this.importeRevertir = this.valoresSelected.reduce(
        (acc, item) => parseFloat((acc + item.imp).toFixed(2)),
        0
      );
    },
    async setDatosTipoComprobante() {
      if (this.tipoComprobanteAnulacionSelected != null) {
        const response = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteAnulacionSelected.id
        );
        let datosTipoComprobante = response;
        // this.tCompEditaFecha = datosTipoComprobante.tCompEditaFecha;
        // this.tCompSigno = datosTipoComprobante.tCompSigno;
        this.tieneNumeradorAutomatico = datosTipoComprobante.numAuto;
        if (datosTipoComprobante.numAutoLetra.trim().length > 0)
          this.letraComprobanteAnula =
            datosTipoComprobante.numAutoLetra.toString();

        if (datosTipoComprobante.numAutoSuc)
          this.sucursalComprobanteAnula =
            datosTipoComprobante.numAutoSuc.toString();

        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected.id,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
        if (this.tieneNumeradorAutomatico == false) {
          this.setAlert({
            type: "warning",
            message: `No está configurado el numerador autómatico. \n Tipo de comprobante: '${datosTipoComprobante.tCompNombre}' \nLetra: '${datosTipoComprobante.numAutoLetra}' \nSucursal: '${datosTipoComprobante.numAutoSuc}'`,
          });
        }
      } else {
        this.letraComprobanteAnula = "";
        this.sucursalComprobanteAnula = "";
        this.nroComprobanteAnula = "";
        this.tieneNumeradorAutomatico = false;
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (!this.isEdit && letra && tCompId && sucursal) {
        this.numeroComprobante = "0";

        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico =
            await this.getTipoCompTieneNumAutomatico({
              tCompId: tCompId?.id ?? tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra,
            });
        } else this.tieneNumeradorAutomatico = false;
      }
    },
    async saveAnulacionPagosVarios() {
      this.loadingSaveBtn = true;
      const data = {
        sesion: 0,
        mProv_id: this.mProvId ? this.mProvId : 0,
        fecha: this.fecha,
        fecContable: this.fechaContable,
        tCompId: this.tipoComprobanteAnulacionSelected.tCompId,
        letra: this.letraComprobanteAnula,
        suc: this.sucursalComprobanteAnula.toString(),
        numero: parseInt(this.nroComprobanteAnula),
        tIvaId: 0,
        neto: 0,
        iva: 0,
        percepcionIva: 0,
        percepcionIb: 0,
        impuestosInternos: 0,
        otrosConceptos: 0,
        importeRevertir: this.importeRevertir,
        total: this.totalValores,
        monedaId: this.monedaPorDefecto.id,
        entFacId: this.empresaSelected.id,
        totalComp: this.monedaDistintaDefecto
          ? this.importeRevertir / this.cotizacion
          : 0,
        obs: this.obsAnulacion,
        toId: null,
        periodo: null,
        provIdCdo: null,
        caeCaiProv: null,
        caeCaiProvFecVen: null,
        tEmision: null,
        ivaComp: null,
        percepcionIvaComp: null,
        percepcionIbComp: null,
        impuestosInternosComp: null,
        cotizacion: this.monedaDistintaDefecto ? this.cotizacion : null,
        otrosConceptosComp: null,
        tipoComprobanteDif: "",
        tcompTieneNumeradorAutomatico: this.tieneNumeradorAutomatico,
        monedaIdComp: this.monedaDistintaDefecto
          ? this.monedaSelected.id
          : null,
        listaValores: this.valoresSelected.map((item) => {
          if (item.fecha !== null)
            item.fecha = helpers.parseDateToIso(item.fechaToShow);
          return item;
        }),
        listaComprobante: [],
        listaImputContable: [],
        listaCentrosCostos: [],
        listaPercepcionIB: [],
        listaAlicuota: [],
        listaPagosRet: [],
        listaCentrosCostos: [],
        listaImputContable: [],
      };

      try {
        const response = await this.grabarAnularPagosVarios(data);
        if (response && response.idError == 0) {
          this.setAlert({
            type: "success",
            message: `Pagos / ingresos anulado con éxito. ${response.mensaje}`,
          });
          this.closeModal();
        } else {
          this.setAlert({
            type: "error",
            message: `Error al grabar: ${response.mensaje}`,
          });
        }
      } catch {}
      this.loadingSaveBtn = false;
    },
    selectAllItems() {
      if (this.selectAll)
        this.valoresSelected = this.valoresSelected.concat(
          this.valoresItems.filter((x) => x.mpIdAnulaVarios == 0)
        );
      else
        this.valoresSelected = this.valoresItems.filter(
          (x) => x.mpIdAnulaVarios !== 0
        );
    },
    selectWhenClickRow(item) {
      const isSelected = this.valoresSelected.some(
        (selectedItem) =>
          selectedItem.mpValId === item.mpValId && item.mpIdAnulaVarios == 0
      );
      if (!isSelected) this.valoresSelected.push(item);
      else
        this.valoresSelected = this.valoresSelected.filter((x) => x !== item);
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>